import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import SEOInputs from "../../Components/PageCard/SEOInputs";
import ProductForm from "../../Components/Products/ProductForm";
import { getProductItem } from "../../REDUX/actions/products";

const ProductItemView = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getProductItem(params.id));
    // eslint-disable-next-line
  }, []);

  const { productItem } = useSelector((state) => state.productsReducer);
  return !productItem ? (
    <Loading />
  ) : (
    <div>
      <ProductForm />
      <Accordion className=" rounded-10 ">
        <SEOInputs
          seo={productItem.attributes.seo.data}
          title={productItem.attributes.name}
          collapsed
        />
      </Accordion>
    </div>
  );
};

export default ProductItemView;

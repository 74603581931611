import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputText from "../sectioninput/formelement/InputText";
import _ from "lodash";
import { syncSmoobuItem, updateProduct } from "../../REDUX/actions/products";
import ProductSubmitButton from "./ProductSubmitButton";
import Uploader from "../Common/Uploader";
import Lists from "../Lists/Lists";
import { Accordion } from "react-bootstrap";
import CustomButton from "../Common/CustomButton";
import LoadingButton from "../Common/LoadingButton";
import Switch from "../Common/Switch";

const ProductForm = () => {
  const dispatch = useDispatch();
  const { productItem, productsLoading } = useSelector(
    (state) => state.productsReducer
  );
  const yesOrNo = [
    { value: true, text: "Oui" },
    { value: false, text: "Non" },
  ];
  const [productInputs, setproductInputs] = useState(
    _.omit(productItem.attributes, [
      "images",
      "mainImage",
      "proximities",
      "createdAt",
      "updatedAt",
      "seo",
    ])
  );
  const handleProductChange = (value, champs) => {
    setproductInputs({ ...productInputs, [champs]: value });
  };

  const [image, setimage] = useState(productItem.attributes.mainImage.data);

  const handleUpdateProduct = () => {
    dispatch(
      updateProduct(productItem.id, {
        area: productInputs.area,
        description: productInputs.description,
        reglement: productInputs.reglement,
        reg_kids: productInputs.reg_kids,
        reg_pets: productInputs.reg_pets,
        reg_smoking: productInputs.reg_smoking,
        reg_checkTime: productInputs.reg_checkTime,
        coup_de_coeur: productInputs.coup_de_coeur,
        recommended: productInputs.recommended,
        taxe: productInputs.taxe,
        cleaningFees: productInputs.cleaningFees,
        mainImage: image.id,
        deposit: productInputs.deposit,
      })
    );
  };

  const handleSyncSmoobuItem = () => {
    dispatch(syncSmoobuItem(productItem.id));
  };
  useEffect(() => {
    setproductInputs(
      _.omit(productItem.attributes, [
        "images",
        "mainImage",
        "proximities",
        "createdAt",
        "updatedAt",
        "seo",
      ])
    );

    setimage(productItem.attributes.mainImage.data);
    // eslint-disable-next-line
  }, [productItem.attributes]);
  const handleImage = (imageSelected) => {
    setimage(imageSelected);
  };

  const itemStyle = "card-body noBorder mb-4 p-0 rounded-10";
  const headerStyle = "mb-0 f24 fw-normal text-orange";

  return (
    <Accordion className=" rounded-10 mt-4 ">
      <Accordion.Item className={itemStyle} eventKey="0">
        <Accordion.Header className="d-flex align-items-center">
          <h2 className={headerStyle}>Séction Générale</h2>
        </Accordion.Header>
        <Accordion.Body>
          <div className="w-30 mb-4 m-auto">
            <Uploader
              targetScreen="tout écran"
              labelinput="Image principale"
              handleImage={handleImage}
              image={image}
              resolution={"Carré"}
            />
          </div>
          <div className="d-flex flex-wrap  justify-content-center">
            <div className="w-50 px-3">
              <InputText
                labelinput="Nom du bien"
                handleChange={handleProductChange}
                champs="name"
                value={productInputs.name}
                disabled
              />
            </div>

            <div className="w-50 px-3">
              <InputText
                labelinput="city"
                handleChange={handleProductChange}
                champs="titre1"
                value={productInputs.city}
                disabled
              />
            </div>
            <div className="w-50 px-3">
              <InputText
                labelinput="Id Smoobu"
                handleChange={handleProductChange}
                champs="smoobuId"
                value={productInputs.smoobuId}
                disabled
              />
            </div>
            <div className="w-50 px-3">
              <InputText
                labelinput="Nombre de personnes"
                handleChange={handleProductChange}
                type="number"
                champs="maxOccupancy"
                value={productInputs.maxOccupancy}
                disabled
              />
            </div>
            <div className="w-50 px-3">
              <InputText
                labelinput="Superficie (ex: 300m²)"
                handleChange={handleProductChange}
                champs="area"
                value={productInputs.area || "non Mentionné"}
              />
            </div>
            <div className="w-50 px-3">
              <InputText
                labelinput="Prix €"
                handleChange={handleProductChange}
                champs="price"
                type="number"
                value={productInputs.price}
                disabled
              />
            </div>
            <div className="w-50 px-3">
              <InputText
                labelinput="Frais de ménage (€)"
                handleChange={handleProductChange}
                champs="cleaningFees"
                type="number"
                value={productInputs.cleaningFees}
              />
            </div>
            <div className="w-50 px-3">
              <InputText
                labelinput="Taxe de séjour (€)"
                handleChange={handleProductChange}
                champs="taxe"
                type="number"
                value={productInputs.taxe}
              />
            </div>
            <div className="w-50 px-3">
              <InputText
                labelinput="Depot de garantie (€)"
                handleChange={handleProductChange}
                champs="deposit"
                type="number"
                value={productInputs.deposit}
              />
            </div>
            <div className="w-50 px-3">
              <InputText
                placeholder={"Arrivée à XXh et départ à XXh"}
                labelinput="Règlement check-in / check-out"
                handleChange={handleProductChange}
                champs="reg_checkTime"
                value={productInputs.reg_checkTime}
              />
            </div>
            <div className="w-100 px-3">
              <Switch
                label={"Coup de Coeur (1 seul autorisé)"}
                values={yesOrNo}
                handleChange={handleProductChange}
                champs={`coup_de_coeur`}
                value={productInputs.coup_de_coeur}
                canBeDisabled
              />
            </div>
            <div className="w-100 px-3">
              <Switch
                label={"Recommandée (Affiché sur la page d'accueil) 3 autorisé"}
                values={yesOrNo}
                handleChange={handleProductChange}
                champs={`recommended`}
                value={productInputs.recommended}
                canBeDisabled
              />
            </div>
            <div className="w-100 px-3">
              <Switch
                label={"Enfants autorisés"}
                values={yesOrNo}
                handleChange={handleProductChange}
                champs={`reg_kids`}
                value={productInputs.reg_kids}
                canBeDisabled
              />
            </div>
            <div className="w-100 px-3">
              <Switch
                label={"Animaux autorisés"}
                values={yesOrNo}
                handleChange={handleProductChange}
                champs={`reg_pets`}
                value={productInputs.reg_pets}
                canBeDisabled
              />
            </div>
            <div className="w-100 px-3">
              <Switch
                label={"Fumer autorisé"}
                values={yesOrNo}
                handleChange={handleProductChange}
                champs={`reg_smoking`}
                value={productInputs.reg_smoking}
                canBeDisabled
              />
            </div>
            <div className="w-100 px-3">
              <InputText
                textArea
                labelinput="Description"
                handleChange={handleProductChange}
                champs="description"
                type="text"
                value={productInputs.description}
              />
            </div>
            <div className="w-100 px-3">
              <InputText
                textArea
                labelinput="Réglement"
                handleChange={handleProductChange}
                champs="reglement"
                type="text"
                value={productInputs.reglement}
              />
            </div>
          </div>
          <div className="d-flex gap-3 my-5">
            <div className="w-50 px-3">
              <ProductSubmitButton
                handleUpdateProduct={handleUpdateProduct}
                productInputs={productInputs}
              />
            </div>
            <div className="w-50 px-3">
              <CustomButton
                buttonText={
                  productsLoading ? <LoadingButton /> : "Synchroniser Smoobu"
                }
                type="orange"
                iconStart={productsLoading ? "" : "check-lg"}
                callBack={handleSyncSmoobuItem}
              />
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item className={itemStyle} eventKey="10">
        <Accordion.Header>
          <h2 className={headerStyle}>Section images du bien</h2>
        </Accordion.Header>
        <Accordion.Body>
          {productItem.attributes.images.data && (
            <Lists
              listData={productItem.attributes.images.data}
              fetchProduct={productItem.id}
              resolution="carré"
            />
          )}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item className={itemStyle} eventKey="15">
        <Accordion.Header>
          <h2 className={headerStyle}>Section Proximités</h2>
        </Accordion.Header>
        <Accordion.Body>
          {productItem.attributes.proximities.data && (
            <Lists
              listData={productItem.attributes.proximities.data}
              fetchProduct={productItem.id}
              resolution="carré"
            />
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ProductForm;

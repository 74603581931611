import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPagesData } from "../../REDUX/actions/pages";
import { pageLink } from "../../utils/utils";
import CustomButton from "../Common/CustomButton";
import Loading from "../Common/Loading";
import { FRONT_SITE_URL } from "../../Api/EndPoints";

const PageList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pagesLoading, pagesData } = useSelector(
    (state) => state.pagesReducer
  );

  useEffect(() => {
    if (!pagesData) {
      dispatch(getPagesData());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getPagesData());
    // eslint-disable-next-line
  }, []);
  console.log(pagesData);
  const goToPage = (page) => {
    navigate(`/pages/${page.id}`, { state: { id: page.id } });
  };

  const viewInSite = (goTo) => {
    window.open(FRONT_SITE_URL + goTo);
  };

  return pagesLoading || !pagesData ? (
    <Loading />
  ) : (
    <div className="bg-white rounded-10 container-fluid p-5 shadowed">
      <div className="row f24 align-items-center">Liste des pages</div>
      <div className="my-4">
        {pagesData.map((page, index) => (
          <div
            key={index}
            className={`w-98 mx-auto my-2 row py-4 align-items-center rounded-10 ${
              index % 2 === 0 ? "bg-lightGrey" : ""
            }`}
          >
            <div className="col-8 text-start f20">{page?.attributes.name}</div>
            <div className="col-2">
              <CustomButton
                buttonText={"Modifier"}
                type="primary"
                iconStart="pencil-fill"
                callBack={() => goToPage(page)}
              />
            </div>
            <div className="col-2">
              <CustomButton
                buttonText={"Consulter"}
                type="primary"
                outlined
                iconStart="eye-fill"
                callBack={() => viewInSite(pageLink(page.attributes.name))}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageList;

import React from "react";
import { useSelector } from "react-redux";
import CustomButton from "../Common/CustomButton";
import LoadingButton from "../Common/LoadingButton";

const ProductSubmitButton = ({ handleUpdateProduct, productInputs }) => {
  const { productsLoading } = useSelector((state) => state.productsReducer);

  return (
    <CustomButton
      buttonText={productsLoading ? <LoadingButton /> : "Modifier"}
      iconStart={productsLoading ? "" : "pencil-fill"}
      callBack={handleUpdateProduct}
    />
  );
};

export default ProductSubmitButton;

// export const END_POINT = "https://poseidon-api-pprod.labo-linkibe.fr/"; //http://localhost:1337/   https://poseidon-apiv2.labo-linkibe.fr/

export const END_POINT = "https://casamar-api.labo-linkibe.fr";
export const FRONT_SITE_URL = "https://casamar-site.labo-linkibe.fr";
export const ProjectName = "CASAMAR";

export const drawerOnglets = [
  {
    name: "Dashboard",
    icon: "bi-columns-gap",
    to: "/",
    showOnlyToAdmin: false,
    view: "dashboard",
  },
  {
    name: "Reservations",
    icon: " bi-file-earmark-fill",
    to: "/reservations",
    showOnlyToAdmin: false,
    view: "reservations",
  },

  {
    name: "Blog",
    icon: "bi-file-earmark-fill",
    to: "/blog",
    showOnlyToAdmin: false,
    view: "blog",
  },
  // {
  //  name: "Réalisation",
  // icon: "bi-calendar-event",
  //  to: "/realisation",
  // showOnlyToAdmin: false,
  //  view: "events",
  // },
  {
    name: "Pages",
    icon: "bi-file-earmark-code",
    to: "/pages",
    showOnlyToAdmin: false,
    view: "pages",
  },

  {
    name: "Produits",
    icon: "bi-shop",
    to: "/products",
    showOnlyToAdmin: false,
    view: "produits",
  },

  // {
  //   name: "Revendeur",
  //   icon: "bi-pin-map",
  //   to: "/showroom",
  //   showOnlyToAdmin: false,
  //   view: "revendeurs",
  // },

  {
    name: "Contacts",
    icon: "bi-envelope-fill",
    to: "/contact",
    showOnlyToAdmin: false,
    view: "contacts",
  },
  {
    name: "Fichiers",
    icon: "bi-card-image",
    to: "/files",
    showOnlyToAdmin: false,
    view: "fichiers",
  },
  {
    name: "Utilisateurs",
    icon: "bi-people-fill",
    to: "/users",
    showOnlyToAdmin: true,
    view: "users",
  },
  {
    name: "Mon Compte",
    icon: "bi-person-circle",
    to: "/account",
    showOnlyToAdmin: false,
    view: "account",
  },

  //NOT SHOW IN DRAWER
  {
    name: "Produits element",
    hideInDrawer: true,
    to: "/products/:id",
    showOnlyToAdmin: false,
    view: "productItem",
  },

  {
    name: "Articles element",
    hideInDrawer: true,
    to: "/article/:id",
    showOnlyToAdmin: false,
    view: "articleItem",
  },

  // {
  //  name: "Events element",
  //  hideInDrawer: true,
  //  to: "/realisation/:id",
  //  showOnlyToAdmin: false,
  //  view: "eventItem",
  // },

  // {
  //   name: "Revendeurs element",
  //   hideInDrawer: true,
  //   to: "/showroom/:id",
  //   showOnlyToAdmin: false,
  //   view: "showRoomItem",
  // },

  {
    name: "Pages element",
    hideInDrawer: true,
    to: "/pages/:id",
    showOnlyToAdmin: false,
    view: "pageItem",
  },
  {
    name: "Contact element",
    hideInDrawer: true,
    to: "/contact/:id",
    showOnlyToAdmin: false,
    view: "contactItem",
  },
  {
    name: "Nouvel utilisateur",
    hideInDrawer: true,
    to: "/newUser",
    showOnlyToAdmin: true,
    view: "newUser",
  },
];

export const pageNames = {
  blog: "articles",
  // events: "Realisation",
  home: "Accueil",
  products: "Page nos proprietes",
  // magasins: "Page Revendeur",
};
export const redirection = {
  // eventRoute: "article",
  blogRoute: "article",
};

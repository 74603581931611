import "./reservations.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import {
  getReservationsData,
  toggleRefundBlocked,
  processRefund,
} from "../../REDUX/actions/reservations";
import { Accordion } from "react-bootstrap";
import moment from "moment";

const Reservations = () => {
  const dispatch = useDispatch();
  const [refundAmount, setRefundAmount] = useState("");
  const [selectedPaymentIntentId, setSelectedPaymentIntentId] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    dispatch(getReservationsData());
  }, [dispatch]);

  const { reservations, loading, error } = useSelector(
    (state) => state.reservationReducer || {}
  );

  const handleToggleRefund = (paymentId, currentStatus) => {
    dispatch(toggleRefundBlocked(paymentId, currentStatus));
  };

  const handleRefund = () => {
    if (selectedPaymentIntentId && refundAmount) {
      dispatch(processRefund(selectedPaymentIntentId, refundAmount, paymentId));
      dispatch(getReservationsData());
      setRefundAmount("");
      setSelectedPaymentIntentId("");
      setPaymentId("");
    }
  };

  const sortedReservations = useMemo(() => {
    if (!reservations) return [];

    const sortedData = [...reservations].sort((a, b) => {
      let valueA, valueB;

      switch (sortColumn) {
        case "De":
          valueA = moment(a.attributes.arrivalDate);
          valueB = moment(b.attributes.arrivalDate);
          break;
        case "À":
          valueA = moment(a.attributes.departureDate);
          valueB = moment(b.attributes.departureDate);
          break;
        case "Remboursement":
          valueA = a.attributes.paiment?.data?.attributes.refund_status;
          valueB = b.attributes.paiment?.data?.attributes.refund_status;
          break;
        default:
          return 0;
      }

      if (sortOrder === "asc") return valueA > valueB ? 1 : -1;
      return valueA < valueB ? 1 : -1;
    });

    return sortedData;
  }, [reservations, sortColumn, sortOrder]);

  const handleSort = (column) => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };

  return (
    <div>
      <h1>Réservations</h1>
      <table className="w-100 text-start mt-5">
        <thead>
          <tr className="d-flex th_style">
            <th className="col-2">Client</th>
            <th className="col-2">Propriété</th>
            <th className="col-2 clickable" onClick={() => handleSort("De")}>
              De
            </th>
            <th className="col-2 clickable" onClick={() => handleSort("À")}>
              À
            </th>
            <th className="col-2">Montant</th>
            <th
              className="col-2 clickable"
              onClick={() => handleSort("Remboursement")}
            >
              Remboursement
            </th>
          </tr>
        </thead>
      </table>

      <div>
        {sortedReservations.length > 0 ? (
          sortedReservations.map((reservation) => (
            <div key={reservation?.id} className="mb-2">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span className="col-2">
                      {reservation.attributes.firstName}{" "}
                      {reservation.attributes.lastName}
                    </span>
                    <span className="col-2">
                      {reservation.attributes.appartment?.data?.attributes
                        ?.name || "N/A"}
                    </span>
                    <span className="col-2">
                      {reservation.attributes.arrivalDate}
                    </span>
                    <span className="col-2">
                      {reservation.attributes.departureDate}
                    </span>
                    <span className="col-2">
                      {reservation.attributes.montant}
                    </span>
                    <span className="col-2">
                      {reservation.attributes.paiment?.data?.attributes
                        .refund_status ? (
                        <div className="refunded"></div>
                      ) : (
                        <div className="not_refunded"></div>
                      )}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex justify-content-between">
                      <div className="text-start w-40 d-flex flex-column gap-3">
                        <div>
                          <p className="m-0">Détails Client:</p>
                        </div>
                        <div>
                          <label htmlFor="client-name">Nom du client:</label>
                          <input
                            disabled
                            id="client-name"
                            type="text"
                            value={`${reservation.attributes.firstName} ${reservation.attributes.lastName}`}
                            readOnly
                            className="styleInput w-100 ps-3 rounded-3 "
                          />
                        </div>
                        <div>
                          <label htmlFor="client-name">Téléphone:</label>
                          <input
                            disabled
                            id="client-name"
                            type="text"
                            value={`${reservation.attributes.phoneNumber}`}
                            readOnly
                            className="styleInput w-100 ps-3 rounded-3 "
                          />
                        </div>
                        <div>
                          <label htmlFor="client-name">E-mail:</label>
                          <input
                            disabled
                            id="client-name"
                            type="text"
                            value={`${reservation.attributes.email}`}
                            readOnly
                            className="styleInput w-100 ps-3 rounded-3 "
                          />
                        </div>
                        <div>
                          <label htmlFor="client-name">Arrivée:</label>
                          <input
                            disabled
                            id="client-name"
                            type="text"
                            value={`${reservation.attributes.arrivalDate}`}
                            readOnly
                            className="styleInput w-100 ps-3 rounded-3 "
                          />
                        </div>
                        <div>
                          <label htmlFor="client-name">Départ:</label>
                          <input
                            disabled
                            id="client-name"
                            type="text"
                            value={`${reservation.attributes.departureDate}`}
                            readOnly
                            className="styleInput w-100 ps-3 rounded-3 "
                          />
                        </div>
                        <div>
                          <label htmlFor="client-name">Caution:</label>
                          <input
                            disabled
                            id="client-name"
                            type="text"
                            value={`${reservation.attributes.deposit}`}
                            readOnly
                            className="styleInput w-100 ps-3 rounded-3 "
                          />
                        </div>
                      </div>

                      <div className="text-start w-45">
                        <p>Détails Remboursement:</p>

                        <p className="d-flex">
                          Remboursement Automatique effectué:
                          {reservation.attributes.paiment?.data?.attributes
                            .refund_status ? (
                            <p className="text-success  ms-3">Oui</p>
                          ) : (
                            <p className="text-danger  ms-3">Non</p>
                          )}
                        </p>
                        <p className="d-flex ">
                          Statut du Remboursement Automatique:{" "}
                          {reservation.attributes.paiment?.data?.attributes
                            .refund_blocked ? (
                            <p className="text-danger ms-3">Bloqué</p>
                          ) : (
                            <p className="text-success ms-3">Autorisé</p>
                          )}
                        </p>

                        <div className="d-flex align-items-center mb-3">
                          <p className="m-0">
                            Bloquer le remboursement Automatique :
                          </p>
                          <label className="toggle-switch ms-3">
                            <input
                              type="checkbox"
                              checked={
                                reservation.attributes.paiment?.data?.attributes
                                  .refund_blocked
                              }
                              onChange={() =>
                                handleToggleRefund(
                                  reservation.attributes.paiment?.data?.id,
                                  reservation.attributes.paiment?.data
                                    ?.attributes.refund_blocked
                                )
                              }
                            />
                            <span className="slider"></span>
                          </label>
                        </div>
                        <p className="d-flex">
                          Statut du Remboursement:{" "}
                          {reservation.attributes.paiment.data.attributes
                            .refund_status ? (
                            <p className="text-success  ms-3">
                              Effectué{" "}
                              {
                                reservation.attributes.paiment.data.attributes
                                  .type
                              }
                            </p>
                          ) : (
                            <p className="text-danger  ms-3">En attente</p>
                          )}
                        </p>

                        <div className="mb-3">
                          <label htmlFor="client-name">
                            Date prévue de Remboursement:
                          </label>
                          <input
                            disabled
                            id="client-name"
                            type="text"
                            value={`${moment(
                              reservation.attributes.departureDate
                            )
                              .add(7, "days")
                              .format("YYYY-MM-DD")}`}
                            readOnly
                            className="styleInput w-100 ps-3 rounded-3 "
                          />
                        </div>
                        <div className="d-flex flex-column mb-3">
                          <p>Montant à rembourser :</p>
                          <label className="d-flex gap-3">
                            <input
                              className="styleInput w-100 ps-3 rounded-3 "
                              disabled={
                                reservation.attributes.paiment.data.attributes
                                  .refund_status
                              }
                              type="number"
                              max={reservation.attributes.deposit}
                              value={refundAmount}
                              onChange={(e) => {
                                setRefundAmount(e.target.value);
                                setSelectedPaymentIntentId(
                                  reservation.attributes.paiment.data.attributes
                                    .paymentIntentId
                                );
                                setPaymentId(
                                  reservation.attributes.paiment.data.id
                                );
                              }}
                            />
                            <button
                              onClick={handleRefund}
                              disabled={
                                reservation.attributes.paiment.data.attributes
                                  .refund_status
                              }
                            >
                              Rembourser
                            </button>
                          </label>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="client-name">
                            Date Remboursement:
                          </label>
                          <input
                            disabled
                            id="client-name"
                            type="text"
                            value={`${
                              reservation.attributes.paiment.data.attributes
                                .date_refund || "Pas encore remboursé"
                            }`}
                            readOnly
                            className="styleInput w-100 ps-3 rounded-3 "
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="client-name">
                            Montant Remboursé:
                          </label>
                          <input
                            disabled
                            id="client-name"
                            type="text"
                            value={`${
                              reservation.attributes.paiment.data.attributes
                                .refunded_amount || "Pas encore remboursé"
                            }`}
                            readOnly
                            className="styleInput w-100 ps-3 rounded-3 "
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ))
        ) : (
          <p>Aucune réservation trouvée.</p>
        )}
      </div>
    </div>
  );
};

export default Reservations;

const initialState = {
  reservations: [],
  loading: false,
  error: null,
  refund: [],
};

const reservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESERVATIONS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "FETCH_RESERVATIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        reservations: action.payload,
      };

    case "FETCH_RESERVATIONS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "UPDATE_REFUND_BLOCKED_SUCCESS":
      return {
        ...state,
        reservations: state.reservations.map((reservation) => {
          // Check if this reservation's paiment has the matching payment ID
          if (
            reservation.attributes.paiment &&
            reservation.attributes.paiment.data.id === action.payload.paimentId
          ) {
            return {
              ...reservation,
              attributes: {
                ...reservation.attributes,
                paiment: {
                  ...reservation.attributes.paiment,
                  data: {
                    ...reservation.attributes.paiment.data,
                    attributes: {
                      ...reservation.attributes.paiment.data.attributes,
                      refund_blocked: action.payload.updatedStatus, // Update refund_blocked status
                    },
                  },
                },
              },
            };
          }
          return reservation;
        }),
      };

    case "UPDATE_REFUND_BLOCKED_FAILURE":
      return {
        ...state,
        error: action.payload,
      };

    case "REFUND_SUCCESS":
      return {
        ...state,
        refund: action.payload,
        loading: false,
      };

    case "REFUND_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reservationReducer;

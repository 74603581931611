// import HomeView from "../Views/HomeView/HomeView";
import { Routes as Switch, Route } from "react-router-dom";
import LoginView from "../Views/LoginView/LoginView";
import DashboardView from "../Views/DashboardView/DashboardView";
import BlogView from "../Views/BlogView/BlogView";
import PagesView from "../Views/PagesView/PagesView";
import ContactView from "../Views/ContactView/ContactView";
import FilesView from "../Views/FilesView/FilesView";
import UsersView from "../Views/UsersView/UsersView";
import AccountView from "../Views/AccountView/AccountView";
import ProductsView from "../Views/ProductsView/ProductsView";
import EventsView from "../Views/EventsView/EventsView";
import ShowRoomItemView from "../Views/ShowRoomItemView/ShowRoomItemView";
import ProductItemView from "../Views/ProductItemView/ProductItemView";
import ArticleItemView from "../Views/ArticleItemView/ArticleItemView";
import EventItemView from "../Views/EventItemView/EventItemView";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import AdminRoutes from "./AdminRoutes";
import NewUserView from "../Views/NewUserView/NewUserView";
import ContactItemView from "../Views/ContactItemView/ContactItemView";
import ShowRoomsView from "../Views/ShowRoomsView/ShowRoomsView";
import PageItemView from "../Views/PageItemView/PageItemView";

import { drawerOnglets } from "../Api/EndPoints";
import Reservations from "../Views/Reservation/ReservationsView";

const Routes = () => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const token = localStorage.getItem("token");
  const handleComponent = (comp) => {
    switch (comp) {
      case "dashboard":
        return <DashboardView />;
      case "blog":
        return <BlogView />;
      case "events":
        return <EventsView />;
      case "pages":
        return <PagesView />;
      case "produits":
        return <ProductsView />;
      case "revendeurs":
        return <ShowRoomsView />;
      case "contacts":
        return <ContactView />;
      case "fichiers":
        return <FilesView />;
      case "users":
        return <UsersView />;
      case "account":
        return <AccountView />;
      case "productItem":
        return <ProductItemView />;
      case "articleItem":
        return <ArticleItemView />;
      case "eventItem":
        return <EventItemView />;
      case "showRoomItem":
        return <ShowRoomItemView />;
      case "pageItem":
        return <PageItemView />;
      case "contactItem":
        return <ContactItemView />;
      case "newUser":
        return <NewUserView />;
      case "reservations":
        return <Reservations />;

      default:
        break;
    }
  };
  return (
    <Switch>
      {/* ---------------------------------- restricted Paths ------------------------------------- */}

      <>
        {drawerOnglets.map((el) => {
          return (
            <Route
              path={el.to}
              exact
              element={
                el.showOnlyToAdmin ? (
                  <AdminRoutes isAdmin={user.isAdmin}>
                    {handleComponent(el.view)}
                  </AdminRoutes>
                ) : (
                  <ProtectedRoute isLoggedIn={user.id}>
                    {handleComponent(el.view)}
                  </ProtectedRoute>
                )
              }
            ></Route>
          );
        })}
      </>

      {/* ---------------------------------- open Paths ------------------------------------- */}

      {!user.id || !token ? (
        <Route path="/login" exact element={<LoginView />}></Route>
      ) : (
        <Route path="/login" element={<Navigate to="/" />} />
      )}
    </Switch>
  );
};

export default Routes;

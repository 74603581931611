import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../REDUX/actions/confirmationModal";
import CustomButton from "../Common/CustomButton";
import { useNavigate } from "react-router-dom";

import { Form } from "react-bootstrap";
import {
  deletArticle,
  publishOrNoPublishArticle,
} from "../../REDUX/actions/articles";
import { redirection } from "../../Api/EndPoints";

const ArticleCard = ({ article, index }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deletArticle(article.id));
  };

  const showModalDelete = () =>
    dispatch(
      openModal({
        type: "delete",
        confirmButtonText: "Supprimer",
        cancelButton: true,
        message: "Êtes-vous sûr(e) de vouloir supprimer l'article' ?",
        callBack: handleDelete,
      })
    );

  const showModalPublish = (checked) => {
    dispatch(
      openModal({
        confirmButtonText: checked ? "Publier" : "Retirer",
        cancelButton: true,
        message: checked
          ? "Êtes-vous sûr(e) de vouloir publier l'article ?"
          : "Êtes-vous sûr(e) de vouloir rendre l'article non publié ?",
        callBack: () => handlePublish(checked),
      })
    );
  };

  const goTo = () => {
    navigate(`/${redirection.blogRoute||"blog"}/${article.id}`, { state: { id: article.id } });
  };
  const handlePublish = (checked) =>
    dispatch(publishOrNoPublishArticle(article.id, checked));

  return (
    <div
      className={`w-98 mx-auto my-2 row py-4 align-items-center rounded-10 ${
        index % 2 === 0 ? "bg-lightGrey" : ""
      }`}
    >
      <div className="col-4 text-start">{article?.attributes.title}</div>

      <div className="col-2 text-start" key={article.id}>
        <Form.Check
          className="me-2"
          type="switch"
          checked={article.attributes.publish}
          onChange={(e) => {
            e.preventDefault();
            showModalPublish(e.target.checked);
          }}
        />
      </div>
      <div className="col-6 text-start row justify-content-start">
        <div className="col-4">
          <CustomButton
            buttonText={"Modifier"}
            iconStart="pencil-fill"
            callBack={goTo}
          />
        </div>
        <div className="col-4">
          <CustomButton
            buttonText={"Supprimer"}
            type="red"
            iconStart="trash3-fill"
            callBack={showModalDelete}
          />
        </div>
        <div className="col-4">
          {/* <CustomButton buttonText={"Supprimer"} type="red" iconStart="trash3-fill" callBack={showModalDelete} /> */}
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;

import API from "../../Api/Api.js";
import { handleError } from "./notifications.js";
import qs from "qs";

export const getReservationsData = (filter) => async (dispatch) => {
  dispatch({ type: "RESERVATIONS_LOADING" });

  const query = qs.stringify(
    {
      filters: { paiment: { id: { $notNull: true } } },
      pagination: { pageSize: 1000 },
      populate: {
        appartment: {
          fields: ["name"],
        },
        paiment: {
          fields: [
            "id",
            "paymentIntentId",
            "refund_status",
            "refund_blocked",
            "date_refund",
            "type",
            "refunded_amount",
          ],
        },
      },
      sort: ["createdAt:desc"],
    },
    {
      encodeValuesOnly: true,
    }
  );

  try {
    const { data } = await API.get(`api/reservations?${query}`);
    console.log("data", data.data);

    dispatch({ type: "FETCH_RESERVATIONS_SUCCESS", payload: data.data });
  } catch (error) {
    dispatch({
      type: "FETCH_RESERVATIONS_FAILURE",
      payload: "Erreur lors du chargement des réservations",
    });
    dispatch(handleError("Erreur lors du chargement des réservations", 1));
    console.error(error);
  }
};
export const toggleRefundBlocked =
  (paimentId, currentStatus) => async (dispatch) => {
    const token = localStorage.getItem("token");

    if (token) {
      API.defaults.headers.common["Authorization"] = token;
    } else {
      dispatch(
        handleError("Token manquant ou expiré. Veuillez vous reconnecter.", 1)
      );
      return;
    }

    try {
      const updatedStatus = !currentStatus;

      await API.put(`api/paiments/${paimentId}`, {
        data: {
          refund_blocked: updatedStatus,
        },
      });

      dispatch({
        type: "UPDATE_REFUND_BLOCKED_SUCCESS",
        payload: { paimentId, updatedStatus },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_REFUND_BLOCKED_FAILURE",
        payload: "Erreur lors de la mise à jour du statut du remboursement",
      });
      dispatch(
        handleError(
          "Erreur lors de la mise à jour du statut du remboursement",
          1
        )
      );
      console.error(error);
    }
  };
export const processRefund =
  (paymentIntentId, refundAmount, paimentId) => async (dispatch) => {
    const token = localStorage.getItem("token");

    if (token) {
      API.defaults.headers.common["Authorization"] = token;
    } else {
      dispatch(
        handleError("Token manquant ou expiré. Veuillez vous reconnecter.", 1)
      );
      return;
    }

    try {
      const { data } = await API.post("api/refund", {
        paymentIntentId,
        refundAmount,
        paimentId,
      });

      if (data.success) {
        // Dispatch an action to update the refund status in the state
        dispatch({
          type: "REFUND_SUCCESS",
          payload: data.refund,
        });
        // You can fetch the updated reservations or update the specific reservation in the state
        dispatch(getReservationsData()); // Fetch updated reservations
      } else {
        dispatch({
          type: "REFUND_FAILURE",
          payload: "Échec du traitement du remboursement.",
        });
      }
    } catch (error) {
      dispatch({
        type: "REFUND_FAILURE",
        payload: error.message || "Erreur lors du traitement du remboursement.",
      });
      dispatch(handleError("Erreur lors du traitement du remboursement.", 1));
    }
  };

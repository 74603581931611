import React from "react";
import { useSelector } from "react-redux";
import EmptyList from "../Common/EmptyList";
import Loading from "../Common/Loading";
import ProductCard from "./ProductCard";

const ProductList = () => {
  const { productsLoading, products } = useSelector(
    (state) => state.productsReducer
  );

  return !products ? (
    <Loading />
  ) : (
    <div className="bg-white rounded-10 container-fluid p-5 shadowed">
      <div className="row f24 align-items-center">
        Liste des produits ({products.length})
      </div>

      {!productsLoading ? (
        <div className="mt-5 d-flex gap-5 flex-wrap justify-content-around">
          {products.length ? (
            products.map((product, index) => (
              <ProductCard key={index} product={product} />
            ))
          ) : (
            <div className="w-100">
              <EmptyList text="Ajouter votre premier produit" />
            </div>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ProductList;

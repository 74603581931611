import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { bgImage, imageSrc } from "../../utils/utils";

import FilesModalSelector from "../Files/FilesModalSelector";
import CustomButton from "./CustomButton";
import Loading from "./Loading";
import "./Uploader.css";

const Uploader = ({
  handleImage,
  image = {},
  resolution = "(1800 * 700)",
  labelinput,
  targetScreen = "desktop",
}) => {
  const [showModal, setshowModal] = useState(false);
  return !image ? (
    <Loading />
  ) : (
    <div className=" col d-flex flex-column align-items-center justify-content-center shadowed p-2">
      {labelinput ? (
        <div className="text-center col-12 my-2">
          {labelinput} pour {targetScreen}
        </div>
      ) : (
        <div className="text-center col-12 my-2">image pour {targetScreen}</div>
      )}

      {image.attributes?.mime.includes("application") ? (
        <div className="uploaderContainer" onClick={() => setshowModal(true)}>
          <i className="bi f60 bi-file-earmark-richtext-fill"></i>
        </div>
      ) : image.attributes?.mime.includes("video") ? (
        <div className="uploaderContainer " onClick={() => setshowModal(true)}>
          <video controls width="100%">
            <source src={imageSrc(image.attributes.url)} type="video/mp4" />

            <source src={imageSrc(image.attributes.url)} type="video/webm" />
          </video>
        </div>
      ) : (
        <div
          className="uploaderContainer "
          style={{ backgroundImage: bgImage(image) }}
          onClick={() => setshowModal(true)}
        ></div>
      )}
      <div className="text-center my-2">{image.attributes?.name}</div>
      <CustomButton
        buttonText="importer"
        iconStart="upload"
        callBack={() => setshowModal(true)}
      />
      <div className="text-center f14 lighter mt-1">
        Résolution : {resolution}
      </div>
      <Modal
        show={showModal}
        size={"xl"}
        onHide={() => setshowModal(false)}
        className="myModal"
      >
        <Modal.Header>
          <div className="container-fluid">
            <div className="row ">
              <div className="col-9"> Sélectionner l'image </div>
              <div className="col-3">
                <CustomButton
                  buttonText={"Sélectionner"}
                  callBack={() => setshowModal(false)}
                />
              </div>
            </div>
          </div>
        </Modal.Header>
        <FilesModalSelector handleImage={handleImage} image={image} />
      </Modal>
    </div>
  );
};

export default Uploader;

import "./Drawer.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { drawerOnglets } from "../../Api/EndPoints";

const Drawer = () => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload(false);
  };

  const location = useLocation();
  const { user } = useSelector((state) => state.usersReducer);

  return (
    <div className="drawerContainer">
      <Link to={"/"} className="logoContainer mx-2"></Link>
      <div className="logoNavigations">
        {drawerOnglets.map((el, index) =>
          el.showOnlyToAdmin && !user.isAdmin
            ? null
            : !el.hideInDrawer && (
                <Link
                  to={el.to}
                  className={`drawLink ${
                    location.pathname === el.to ? "inpage" : ""
                  }`}
                  key={index}
                >
                  <i className={`bi ${el.icon} ms-4 my-2 f26`}></i>{" "}
                  <div>{el.name}</div>
                </Link>
              )
        )}
      </div>
      <div className="logout f14 mb-2" onClick={handleLogout}>
        <div className="d-flex drawLink align-items-center ">
          <i className="bi bi-power ms-4 f26"></i> Déconnexion
        </div>
      </div>
    </div>
  );
};

export default Drawer;

import React from "react";
import { useNavigate } from "react-router-dom";
import { productBgImage } from "../../utils/utils";
import CustomButton from "../Common/CustomButton";
import "./ProductCard.css";

const ProductCard = ({ product }) => {
  const navigate = useNavigate();
  console.log(productBgImage(product.mainImage));

  const goToProduct = () =>
    navigate(`/products/${product.id}`, { state: { id: product.id } });

  return (
    <div className="">
      <div
        className="productCardImage"
        style={{ backgroundImage: productBgImage(product.mainImage) }}
      ></div>

      <div className="text-start f18 mt-2">{product.name}</div>
      <div className="text-start f14 ">{product.city}</div>
      <div className="text-start f12">Smoobu Id : {product.smoobuId}</div>
      <div className="text-start f12 ">
        {" "}
        Max-Personnes :{" "}
        {product.maxOccupancy || (
          <span className="text-red f12">non mentionné</span>
        )}
      </div>
      <div className="text-start f12 ">
        {" "}
        Chambres :{" "}
        {product.bedrooms || (
          <span className="text-red f12">non mentionné</span>
        )}
      </div>
      <div className="text-start f12 ">
        {" "}
        Prix :{" "}
        {product.price || <span className="text-red f12">non mentionné</span>}
      </div>

      <div className="col-9 mt-4 m-auto">
        <CustomButton
          iconStart="pencil-fill"
          callBack={goToProduct}
          buttonText={"Editer"}
        />
      </div>
    </div>
  );
};

export default ProductCard;
